import React from "react";
import AwardsImg from "../../../images/the-negotiator-awards-2022.webp"
import { Link } from "@StarberryUtils"
import "./Banner.scss"
const AwardBanner = () => {
  return (
      <div className="">
        <div class="row">
          <div class="col-sm-5 col-5 m-auto"><img src={AwardsImg} alt="The Negotiator Awards 2022 - for the Best Estate Agents in Manchester" /></div>
          <div class="col-sm-7 col-7 m-auto">
            The Negotiator Awards 2022 <b>"Website of the Year"</b> winner
            <div className="more-link">
              <Link target="_blank" to={"/about-us/blog/bentley-hurst-have-won-gold/"}><i className="icon-right-arrow"></i>View more</Link>
            </div>
            </div>
        </div>
      </div>      
  )
}
export default AwardBanner
